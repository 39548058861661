<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          absolute
          fab
          bottom
          right
          v-bind="attrs"
          v-on="on"
          class="accent white--text"
          @click="openDialog()"
          style="position: fixed; bottom: 25px; right: 25px"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Click here to add new record</span>
    </v-tooltip>
    <v-toolbar flat elevation="5">
      <v-badge color="accent" :content="documents.length" overlap>
        <v-toolbar-title>Survey Plans</v-toolbar-title>
      </v-badge>
      <v-btn icon @click="getDocuments()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <!-- <v-col col-md-6 class="mt-7">
        <v-select
          v-model="search"
          :items="states"
          label="Search by lga"
          prepend-inner-icon="mdi-map-marker-radius"
          required
          dense
        ></v-select
      ></v-col>
      <v-col col-md-6 class="mt-7">
        <v-select
          v-model="search"
          :items="states"
          label="Search by year"
          required
          dense
        ></v-select
      ></v-col>
      <v-col col-md-6 class="mt-7">
        <v-select
          v-model="search"
          :items="states"
          label="Search by month"
          required
          dense
        ></v-select
      ></v-col> -->
      <v-pagination
        class="hidden-sm-and-down"
        v-model="list_paging.page"
        :length="Math.ceil(filteredConnections.length / list_paging.perPage)"
      >
      </v-pagination>
      <v-spacer></v-spacer>
      <v-col class="mb-n6 col-lg-3 col-xs-6" v-show="show_search">
        <v-text-field
          v-model="search"
          placeholder="search"
          dense
          required
        ></v-text-field>
      </v-col>
      <v-btn icon @click="show_search = !show_search">
        <v-icon :color="searchStatusColor">mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row no-gutters>
      <v-col
        v-for="(item, i) in visiblePages"
        :key="i"
        col-lg-3
        col-md-4
        col-sm-6
        col-xs-4
      >
        <v-card class="my-1 mr-1" tile>
          <v-list-item>
            <v-list-item-avatar tile x-large>
              <img
                v-if="item.qrCodePath == null"
                width="25"
                aspect-ratio="1"
                src="../assets/logo.png"
              />
              <img
                v-else
                width="25"
                aspect-ratio="1"
                :src="
                  'https://api.flexiblebase.com/resources/veridocs/' +
                  item.PictureName
                "
              />
            </v-list-item-avatar>
            <v-list-item-avatar v-show="false" tile x-large>
              <div id="re_qrcode"></div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon color="primary" x-small>mdi-account</v-icon>
                {{ item.title }}
                <v-icon color="primary" x-small>mdi-numeric</v-icon
                >{{ item.planNumber }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon color="primary" x-small>mdi-map-marker-radius</v-icon>
                {{ item.location + ", " + item.lga + ", " + item.state }} |
                <v-icon color="primary" x-small
                  >mdi-mdi-chart-areaspline-variant</v-icon
                >
                {{ item.size }}
                <v-icon color="primary" x-small>mdi-calendar-clock</v-icon
                >{{ new Date(item.planDate).toLocaleDateString() }}
               <v-icon v-if='item.planStatus == "Lodged"' color="primary" small>info</v-icon>
               <v-icon v-else-if="item.planStatus == 'Collected' || item.planStatus == 'Submitted'" color="warn" small>info</v-icon>
               <v-icon v-else color="accent" small>info</v-icon>
               
               <span v-if="item.planStatus">{{ item.planStatus }}</span> 
               <span v-else>Draft</span>               
               <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon @click="reGenerateQRCode(item)"
                        >mdi-refresh</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Click here to regenerate bad qrcode</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>                 
                  <v-list-item @click="showEditDialog(item)">
                    <v-icon class="pa-1" small>mdi-pencil</v-icon>
                    <v-list-item-title>Edit Record</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="reGenerateQRCode(item)">
                    <v-icon class="pa-1" small>qr_code</v-icon>
                    <v-list-item-title>Regenerate QR</v-list-item-title>
                  </v-list-item>
                  <v-list-item disabled @click="uploadSurveyPlanCAD(item)">
                    <v-icon class="pa-1" small>mdi-cloud</v-icon>
                    <v-list-item-title
                      >Upload Survey Plan(.dwg)</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item disabled @click="uploadSurveyPlanPDF(item)">
                    <v-icon class="pa-1" small>mdi-cloud</v-icon>
                    <v-list-item-title
                      >Upload Survey Plan(.pdf)</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item disabled @click="uploadRedCopy(item)">
                    <v-icon class="pa-1" small>mdi-cloud</v-icon>
                    <v-list-item-title>Upload Red Copy(.dwg)</v-list-item-title>
                  </v-list-item>
                  <v-list-item disabled @click="uploadProofRedCopy(item)">
                    <v-icon class="pa-1" small>mdi-cloud</v-icon>
                    <v-list-item-title
                      >Upload Proof of Lodgment</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="deleteRecord(i, item)">
                    <v-icon class="pa-1" small>mdi-delete</v-icon>
                    <v-list-item-title>Delete Record</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
      <v-divider />
      <v-pagination
        v-model="list_paging.page"
        :length="Math.ceil(filteredConnections.length / list_paging.perPage)"
      >
      </v-pagination>
    </v-row>
    <v-dialog v-model="showDialog" max-width="900px">
      <v-card>
        <v-stepper v-model="page" alt-labels>
          <v-stepper-header>
            <v-stepper-step step="1" :complete="page > 1">
              Plan Details <small>supply all details</small></v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="page > 2">
              Preview
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="page > 3" editable>
              Generate QRCode
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row class="mt-2">
                <v-col col-md-12>
                  <v-text-field
                    v-model="document.title"
                    prepend-inner-icon="mdi-certificate-outline"
                    dense
                    label="Document title*"
                    hint="e.g. SURV. DAMILOLA KOLAWOLE"
                    persistent-hint
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    v-model="document.location"
                    prepend-inner-icon="mdi-map-marker-radius"
                    dense
                    label="Site location*"
                    hint="e.g. Ojodu"
                    persistent-hint
                  ></v-text-field>
                </v-col>

                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    v-model="document.lga"
                    label="LGA*"
                    dense
                    prepend-inner-icon="mdi-map-marker-radius"
                    required
                    hint="e.g. Ikeja"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col col-md-4 col-xs-12>
                  <v-select
                    v-model="document.state"
                    :items="states"
                    label="Select a state"
                    prepend-inner-icon="mdi-map-marker-radius"
                    required
                    dense
                    hint="e.g. Lagos"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-3 col-xs-12>
                  <v-text-field
                    type="Number"
                    v-model="document.size"
                    label="Size"
                    prepend-inner-icon="mdi-chart-areaspline-variant"
                    dense
                    required
                    hint="e.g. 648.564"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col col-md-6 col-xs-12>
                  <v-text-field
                    v-model="document.planNumber"
                    prepend-inner-icon="mdi-numeric"
                    dense
                    label="Plan Number*"
                    hint="e.g. ABC/1234/001/2022/LA"
                    persistent-hint
                    required
                  ></v-text-field>
                </v-col>
                <v-col col-md-3 col-xs-12>
                  <v-text-field
                    type="date"
                    dense
                    prepend-inner-icon="mdi-calendar-clock"
                    v-model="document.planDate"
                    label="Plan Date*"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    v-model="document.station"
                    label="station"
                    prepend-inner-icon="mdi-select-marker"
                    dense
                    required
                    hint="e.g. SC/LA AB1234YZ"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    type="Number"
                    v-model="document.northing"
                    dense
                    prepend-inner-icon="mdi-select-marker"
                    label="northing*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    type="Number"
                    v-model="document.easting"
                    label="easting"
                    prepend-inner-icon="mdi-select-marker"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider class="mt-5"></v-divider>
              <v-card-actions>
                <v-btn raised color="warn" tile dark @click="showDialog = false"
                  >Cancel</v-btn
                >
                <v-btn raised color="danger" tile dark @click="clearForm()"
                  >Clear</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn class="primary white--text" raised tile @click="page = 2"
                  >Continue</v-btn
                >
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row class="mt-2">
                <v-col col-md-12 col-xs-12>
                  <v-text-field
                    v-model="document.title"
                    dense
                    disabled
                    label="Document title*"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    v-model="document.location"
                    disabled
                    dense
                    label="Site location*"
                  ></v-text-field>
                </v-col>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    v-model="document.lga"
                    label="LGA*"
                    disabled
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col col-md-4 col-xs-12>
                  <v-select
                    v-model="document.state"
                    disabled
                    :items="states"
                    label="Select a state"
                    required
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-3 col-xs-12>
                  <v-text-field
                    v-model="document.size"
                    disabled
                    label="Size"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col col-md-6 col-xs-12>
                  <v-text-field
                    v-model="document.planNumber"
                    disabled
                    dense
                    label="Plan Number*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col col-md-3 col-xs-12>
                  <v-text-field
                    type="date"
                    dense
                    disabled
                    v-model="document.planDate"
                    label="Plan Date*"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    v-model="document.station"
                    disabled
                    label="station"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    type="numeric"
                    v-model="document.northing"
                    disabled
                    dense
                    label="northing*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col col-md-4 col-xs-12>
                  <v-text-field
                    v-model="document.easting"
                    label="easting"
                    dense
                    disabled
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mt-5"></v-divider>
              <v-card-actions>
                <v-btn raised color="warn" tile @click="page = 1"
                  >Previous</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="primary" tile raised @click="saveDocument()">
                  Continue
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="text-center">
                <h3>{{ document.title }}</h3>
                <small>
                  {{ document.planNumber }} | {{ document.size }}SQM |
                  {{ document.lga }}</small
                >
                <!-- <v-divider></v-divider> -->
                <div
                  style="
                    width: 210px;
                    height: 210px;
                    border: solid, thick;
                    border-color: blue;
                    margin: auto;
                  "
                >
                  <div id="qrcode"></div>
                </div>
              </v-card>
              <v-divider class="mt-5"></v-divider>
              <v-card-actions>
                <v-btn raised color="warn" tile @click="page = 2"
                  >Previous</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="primary" tile raised @click="saveQRcode()">
                  Submit
                </v-btn>
                <v-btn color="primary darken-2" tile raised @click="Download()">
                  Download
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="900px">
      <v-card>
        <v-card-title> Edit Plan Information </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col col-md-12>
              <v-text-field
                v-model="document.title"
                prepend-inner-icon="mdi-certificate-outline"
                dense
                label="Document title*"
                persistent-hint
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-3 col-xs-12>
              <v-text-field
                v-model="document.location"
                prepend-inner-icon="mdi-map-marker-radius"
                dense
                label="Site location*"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col col-md-3 col-xs-12>
              <v-text-field
                v-model="document.lga"
                label="LGA*"
                dense
                disabled
                prepend-inner-icon="mdi-map-marker-radius"
                required
                hint="You cannot change LGA"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col col-md-3 col-xs-12>
              <v-text-field
                v-model="document.state"
                label="State*"
                dense
                disabled
                prepend-inner-icon="mdi-map-marker-radius"
                required
                hint="You cannot change state"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col col-md-3 col-xs-12>
              <v-select
                v-model="document.planStatus"
                :items="planStatus"
                label="Select plan status"
                prepend-inner-icon="mdi-map-marker-radius"
                required
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-3 col-xs-12>
              <v-text-field
                type="Number"
                v-model="document.size"
                label="Size"
                prepend-inner-icon="mdi-chart-areaspline-variant"
                dense
                required
                hint="e.g. 648.564"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col col-md-6 col-xs-12>
              <v-text-field
                v-model="document.planNumber"
                prepend-inner-icon="mdi-numeric"
                dense
                label="Plan Number*"
                hint="e.g. ABC/1234/001/2022/LA"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col col-md-3 col-xs-12>
              <v-text-field
                dense
                disabled
                prepend-inner-icon="mdi-calendar-clock"
                v-model="document.planDate"
                label="Plan Date*"
                required
              ></v-text-field>
            </v-col>
            <v-col col-md-3 col-xs-12>
              <v-text-field
                type="date"
                :value="document.planDate"
                dense
                prepend-inner-icon="mdi-calendar-clock"
                v-model="document.planDate"
                label="Change Plan Date*"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-4 col-xs-12>
              <v-text-field
                v-model="document.station"
                label="station"
                prepend-inner-icon="mdi-select-marker"
                dense
                required
                hint="e.g. SC/LA AB1234YZ"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col col-md-4 col-xs-12>
              <v-text-field
                type="Number"
                v-model="document.northing"
                dense
                prepend-inner-icon="mdi-select-marker"
                label="northing*"
                required
              ></v-text-field>
            </v-col>
            <v-col col-md-4 col-xs-12>
              <v-text-field
                type="Number"
                v-model="document.easting"
                label="easting"
                prepend-inner-icon="mdi-select-marker"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions>
          <v-btn raised color="warn" tile dark @click="editDialog = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary white--text" raised tile @click="saveEdits()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showProgress"
      class="pa-3"
      hide-overlay
      persistent
      width="400"
    >
      <v-card>
        <v-card-text class="white">
          {{ progressMessage }}
          <v-progress-linear
            indeterminate
            color="primary"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
      >{{ alert.text }}</v-snackbar
    >
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showQRDialog" max-width="300">
      <v-card>
        <div>
          <v-card-title class="title"> QR Code </v-card-title>
        </div>
        <div id="re_qrcode1"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="showQRDialog = false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "../store/modules/Network";
import router from "../router";
import AppSettings from "../store/AppSettings";
import { VMessages } from "vuetify/lib";
const apiUrl = AppSettings.API_ENDPOINT;
export default {
  data: function () {
    return {
      editDialog: false,
      list_paging: {
        page: 1,
        perPage: 10,
        pages: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
      planStatus:['Draft', 'Collected', 'Submitted', 'Lodged', 'Issues'],
      showQRDialog: false,
      progressMessage: "Processing your request, please wait...",
      page: 1,
      valid: true,
      hide: false,
      account_email: "",
      acceptTerms: false,
      profile_id: "",
      qrSecurity: "",
      qrCodePath: "",
      documents: [],
      document: {
        email: "",
        title: "",
        location: "",
        lga: "",
        state: "",
        size: null,
        planNumber: "",
        planDate: null,
        station: "",
        easting: null,
        northing: null,
        planStatus: "Draft",
      },
      emptyDocument: {
        email: "",
        title: "",
        location: "",
        lga: "",
        state: "",
        size: null,
        planNumber: "",
        planDate: null,
        station: "",
        easting: null,
        northing: null,
        planStatus: "Draft",
      },
      Profile: {},
      file: { name: "" },
      loaded: false,
      search: "",
      searchStatusColor: "primary",
      show_search: false,
      connections: [],
      states: ["Lagos", "Ogun", "Oyo", "Osun", "Ondo", "Ekiti", "Kwara"],
      //lga: ["Lagos", "Ogun", "Oyo", "Osun", "Ondo", "Ekiti", "Kwara"],
      //states: ["Lagos", "Ogun", "Oyo", "Osun", "Ondo", "Ekiti", "Kwara"],
      databases: [],
      emptyConnection: {
        server: "",
        userId: "",
        password: "",
        database: "",
        dbType: "",
        port: "",
        description: "",
        status: "",
        time: null,
        db: { noOfTables: 0, noOfViews: 0 },
      },
      vendors: ["mssql", "mysql", "pgsql", "oracle"],
      dbType: [
        { text: "mssql", port: 1433 },
        { text: "mysql", port: 3306 },
        { text: "pgsql", port: 5432 },
        { text: "oracle", port: 1521 },
      ],
      showDialog: false,
      showProgress: false,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Veridocs Security",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top",
      },
    };
  },
  filters: {
    getIcon: function (value) {
      return "http://localhost:8080/public/img/icons/" + value + ".png";
    },
    getIconColor(value) {
      if (value == "done") {
        return "success";
      } else {
        return "error";
      }
    },
  },
  computed: {
    userName: function () {
      return this.$store.getters.getUserName;
    },
    filteredConnections() {
      if (this.documents) {
        if (this.search !== "") {
          const search = this.search.toLowerCase();

          return this.documents.filter(
            (item) =>
              item.title.toLowerCase().includes(search) ||
              item.planNumber.toLowerCase().includes(search) ||
              item.planDate.toLowerCase().includes(search) ||
              item.station.toLowerCase().includes(search) ||
              item.lga.toLowerCase().includes(search)
          );
        }
        return this.documents;
      } else {
        return [];
      }
    },
    visiblePages() {
      return this.filteredConnections.slice(
        (this.list_paging.page - 1) * this.list_paging.perPage,
        this.list_paging.page * this.list_paging.perPage
      );
    },
  },
  methods: {
    showEditDialog(item) {
      this.document = item;
      this.editDialog = true;
    },
    deleteRecord(index, item) {
      const vm = this;
      Api()
        .delete("api/veridocs/document/" + item.qrSecurity)
        .then((response) => {
          //alert(response.data)
          this.documents.splice(index, 1);
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "blue"),
            (vm.alert.text = response.data)
          );
        })
        .catch(function (ex) {
          if (!ex.response) {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.message)
            );
          } else {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          }
        });
    },
    openDialog() {
      return (
        (this.showDialog = true),
        (this.page = 1),
        (this.document = this.emptyDocument)
      );
    },
    clearForm: function () {
      this.document = this.emptyDocument;
    },
    generateQRCode: function makeCode() {
      var qrcode = new QRCode(document.getElementById("qrcode"), {
        width: 200,
        height: 200,
        colorDark: "black",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      qrcode.makeCode(this.qrCodestring);
    },
    reGenerateQRCode(item) {
      var vm = this;
      this.progressMessage = "Re-generation  QR security code...";
      this.showProgress = true;
      const qrCode = item.qrSecurity.trim();
      var mstring = "securesurveyplan.com/plans/?qr=" + qrCode;

      var qrcodeImg = new QRCode(document.getElementById("re_qrcode"), {
        width: 200,
        height: 200,
        colorDark: "black",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(mstring);
      qrcodeImg.clear();
      qrcodeImg.makeCode(mstring);
      this.alert.snackbar = true;
      this.alert.bgcolor = "primary";
      this.alert.text =
        "The QRcode for plan: " +
        item.planNumber +
        " was re-created successfully";

      this.progressMessage = "Saving QR to the database...";
      var canvas = document.getElementById("re_qrcode").querySelector("canvas");
      var dataURL = canvas.toDataURL();
      this.qrCodePath = dataURL;
      let qr = qrCode;
      let data = { base64String: dataURL };
      Api()
        .post("api/veridocs/document/" + qr, data)
        .then((response) => {
          //.documents.unshift(this.document);
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.snackbar = true),
            (vm.alert.bgcolor = "blue"),
            (vm.alert.text = "QR code was saved successfully"),
            (vm.progressMessage = "Reloading data from the server..."),
            this.getDocuments()
          );
        })
        .catch(function (ex) {
          if (!ex.response) {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.message)
            );
          } else {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          }
        });
    },
    uploadDocument(item, title, ext) {
      var vm = this;
      this.progressMessage = "Uploading " + title + " to the cloud";
      this.showProgress = true;
      const qrCode = item.qrSecurity.trim();
      this.progressMessage = "Saving QR to the database...";
      var canvas = document
        .getElementById("fileUpload")
        .querySelector("canvas");
      var dataURL = canvas.toDataURL();
      this.qrCodePath = dataURL;
      let qr = qrCode;
      let data = { base64String: dataURL };
      Api()
        .post("api/veridocs/document/" + qr, data)
        .then((response) => {
          //.documents.unshift(this.document);
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.snackbar = true),
            (vm.alert.bgcolor = "blue"),
            (vm.alert.text = "QR code was saved successfully"),
            (vm.progressMessage = "Reloading data from the server..."),
            this.getDocuments()
          );
        })
        .catch(function (ex) {
          if (!ex.response) {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.message)
            );
          } else {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          }
        });
    },
    saveEdits: function () {
      const vm = this;
      this.showProgress = true;
      this.document.email = this.userName;
      //alert(JSON.stringify(this.document))
      const id = this.document.qrSecurity;
      Api()
        .post("api/veridocs/document/edits/" + id, this.document)
        .then((response) => {
          //this.alert.snackbar = true;
          this.alertDialog = true
          this.alert.bgcolor = "primary";
          this.alert.text = "Edits was saved successfully";
          this.alert.text = response.data
          this.showProgress=false;
          this.editDialog = false
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    saveDocument: function () {
      const vm = this;
      this.showProgress = true;
      this.document.email = this.userName;
      //alert(JSON.stringify(this.document))
      Api()
        .post("api/veridocs/document", this.document)
        .then((response) => {
          this.qrSecurity = response.data.qrSecurity;
          this.showProgress = false;
          const qrCode = response.data.qrSecurity;
          var mstring = "securesurveyplan.com/plans/?qr=" + qrCode;
          var qrcodeImg = new QRCode(document.getElementById("qrcode"), {
            width: 200,
            height: 200,
            colorDark: "black",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
          qrcodeImg.clear();
          qrcodeImg.makeCode(mstring);

          this.alert.snackbar = true;
          (this.alert.bgcolor = "primary"),
            (this.alert.text = "Survey document was created successfully");
          this.page = 3;
          //var qrs = qrcodeImg.makeImage;
          //alert (qrs)
          this.qrGenerated = true;
        })
        .catch(function (ex) {
          //alert(JSON.stringify(ex.response))
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    getDocuments: function () {
      const vm = this;
      this.showProgress = true;
      Api()
        .get("api/veridocs/" + this.userName + "/Document")
        .then((response) => {
          vm.showProgress = false;
          //console.log(response.data);
          return (this.documents = response.data);
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    saveQRcode: function () {
      const vm = this;
      this.showProgress = true;
      //get the qr div, then find the canvas element inside it
      var canvas = document.getElementById("qrcode").querySelector("canvas");
      var dataURL = canvas.toDataURL();
      this.qrCodePath = dataURL;

      var qr = this.qrSecurity;
      let data = { base64String: dataURL };
      Api()
        .post("api/veridocs/document/" + qr, data)
        .then((response) => {
          this.documents.unshift(this.document);
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "blue"),
            (vm.alert.text = response.data)
          );
        })
        .catch(function (ex) {
          if (!ex.response) {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.message)
            );
          } else {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          }
        });
    },
    Download: function () {
      this.showProgress = true;
      var canvas = document.getElementById("qrcode").querySelector("canvas");
      var dataURL = canvas.toDataURL();
      var link = document.createElement("a");
      link.download = this.document.title + ".png";
      // Construct the uri
      var uri = dataURL;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      // Cleanup the DOM
      document.body.removeChild(link);
      //delete link;
      this.alert.text = "QR Security code was downloaded successfully";
      this.showProgress = false;
    },
    registerNew: function () {
      app.qrSave = false;
      app.qrGenerated = false;
      app.acceptTerms = false;
      app.Document = {};
    },
    onFileUpload(evt) {
      this.file = evt[0];
      this.createImage(this.file);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.Profile.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (e) {
      this.Profile.image = "";
    },
    Clear: function () {
      this.Profile = {};
    },
    setPort() {
      if (this.connection.dbType == "mssql") {
        this.connection.port = 1433;
      } else if (this.connection.dbType == "mysql") {
        this.connection.port = 3306;
      } else if (this.connection.dbType == "pgsql") {
        this.connection.port = 5432;
      } else if (this.connection.dbType == "oracle") {
        this.connection.port = 1521;
      }
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
    removeConnection(i) {
      this.connections.splice(i, 1);
      const connections = this.connections;
      localStorage.setItem(
        "connections",
        this.encriptPass(JSON.stringify(connections))
      );
    },
    GetTables(connection) {
      this.showProgress = true;
      const vm = this;
      try {
        Api()
          .post("api/database/tables", connection)
          .then((response) => {
            var today = new Date();
            var time =
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds();
            connection.time = time;
            this.$store.commit("setTables", response.data);
            this.$store.commit("setConnection", connection);
            router.push("Tables");
            return (
              (this.showProgress = false),
              (this.tables = response.data),
              (this.showDialog = false),
              (this.alert.snackbar = true),
              (this.alert.text = response.data.message)
            );
          })
          .catch(function (ex) {
            if (ex.response !== undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
  },
  created() {
    return this.getDocuments();
  },
};
</script>

<style lang="scss" scoped>
.list-db-featureclass {
  margin: 10px;
  max-height: 200px;
  overflow: auto;
}
</style>
