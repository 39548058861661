<template>
  <v-footer padless elevation="5">
    <v-card app tile class="text-center" style="width:100%">
      <!-- <v-card-text>
        <v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
          <v-icon size="24px">{{ icon }}</v-icon>
        </v-btn>
      </v-card-text>-->
      <!-- <v-card-text class="white-text pt-3 float-right">Support | Terms | Privacy Policy</v-card-text> -->
      <v-card-text class="mb-1"><span class="v-card-text mr-4">Support | Terms | Privacy Policy</span>
        <span>Copyright &copy; 2017 - {{ new Date().getFullYear() }} —
        Geospatial Information Resource Limited</span>  
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"]
  }),
  computed: {
    connection: function() {
      return this.$store.getters.getConnection;
    }
  }
};
</script>
