import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Home from '../views/Home.vue'
import Tables from '../views/Tables.vue'
import SelTable from '../views/SelTable.vue'
import Design from '../views/Design.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import ChangePassword from '../views/ChangePassword.vue'
import ForgetPassword from '../views/RecorverPassword.vue'
import VerifyOTP from '../views/VerifyOTP.vue'
//import ChangePassword from '../views/ChangePassword.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: true, requiresAdmin: false }
  },
  {
    path: '/Design',
    name: 'Design',
    component: Design,
    meta: { requiresAuth: true, requiresAdmin: false}
  },
  {
    path: '/Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/SignUp',
    component: SignUp,
    // meta: { requiresAuth: false }
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: true}
  },
  {
    path: '/VerifyOTP',
    name: 'VerifyOTP',
    component: VerifyOTP
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/SelTable',
    name: 'SelTable',
    component: SelTable,
    meta: { requiresAuth: true, requiresAdmin: false }
  },
  {
    path: '/Tables',
    name: 'Tables',
    component: Tables,
    meta: { requiresAuth: true, requiresAdmin: false }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode:"hash",
  routes
})

export default router
