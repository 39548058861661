import router from '../../router'
import Api from '../modules/Network'
import store from '../../store/index'

import AppSettings from '../AppSettings'
//const apiUrl = AppSettings.API_ENDPOINT;
const Api_Endpoint = AppSettings.API_ENDPOINT

const state = {
  users: [],
  loginId: '',
  emailId: null,
  idToken: null,
  password: null,
  validity: 3600, // 1 hour or 60min or 3600 sec or 3600*1000 milliseconds
  loginStatus: null,
  alert: {
    status: false,
    message: ''
  },
  roles: [],
  roleList: [],
  role: null,
  mfa: false,
  email: null,
  userAccount: {
    token: null,
    mfa: false,
    role: null,
    userName: null,
    expirationDate: null,
    loginStatus: false,
    expiresIn: 0,
    rememberMe: false
  },
  noUser: {
    token: null,
    mfa: false,
    role: null,
    userName: null,
    expirationDate: null,
    loginStatus: false,
    expiresIn: 0
  },
  otpSent: null
}
const configResponse = function (r, rememberMe) {
  const now = new Date()
  const expirationDate = new Date(now.getTime() + r.expiresIn * 1000)
  if (expirationDate == undefined) {
    return router.replace('/login')
  }
  return {
    userName: r.userName,
    token: r.token,
    expirationDate: expirationDate,
    role: r.role,
    loginStatus: true,
    rememberMe: rememberMe
  }
}
const getters = {
  isAdmin(state) {
    return state.roles.admin
  },
  isAccount(state) {
    return state.roles.account
  },
  isSurveyor(state) {
    return state.roles.surveyor
  },
  isManager(state) {
    return state.roles.manager
  },
  isSurcon(state) {
    return state.roles.surcon
  },
  isSystem(state) {
    return state.roles.system
  },

  getUserName: state => {
    return state.userAccount.userName
  },
  getUserAccount: state => {
    return state.userAccount
  },
  getLoginStatus: state => {
    return state.loginStatus
  },
  getLoginUser: state => {
    return state.loginId
  },
  getEmailId: state => {
    return state.emailId
  },
  getIdToken: state => {
    return state.idToken
  },
  isAuthenticated(state) {
    return state.loginStatus
  },
  getAllRoles: state => {
    return state.roleList
  },
  getAllUsers: state => {
    return state.users
  },
  getOTPStatus: state => {
    return state.otpSent
  }
}

const mutations = {
  setAutoLogout: () => {
    setTimeout(() => {
      localStorage.removeItem('expirationDate')
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('userName')
      localStorage.removeItem('userAccount')
      localStorage.removeItem('rememberMe')
      router.replace('/login')
    }, state.userAccount.expiresIn)
  },
  clearAuthCredentials() {
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    localStorage.removeItem('userName')
    localStorage.removeItem('userAccount')
    localStorage.removeItem('rememberMe')

    state.userAccount = state.noUser
    state.loginStatus = false
  },
  setUserName: (state, payload) => {
    state.email = payload.email
  },
  setAuthCredentialsToLStorage(state, credentials) {
    //alert(JSON.stringify(credentials))
    localStorage.setItem('token', credentials.token)
    localStorage.setItem('userName', credentials.userName)
    localStorage.setItem('role', credentials.role)
    localStorage.setItem('rememberMe', credentials.rememberMe)
    localStorage.setItem('userAccount', credentials)

    if (credentials.rememberMe == true) {
      localStorage.setItem('expirationDate', credentials.expirationDate)
    }
    state.userAccount = credentials
    state.loginStatus = true

    return router.replace('/')
    //store.commit('setProgressStatus', false)
  },

  setAuthUserRoles: (state, credentials) => {
    const roles = {
      admin: false,
      account: false,
      surveyor: false,
      surcon: false,
      manager: false,
      appsn: false,
      system: false
    }
    const role = credentials.role

    if (role == 'Account') {
      roles.account = true
    }
    if (role == 'Admin') {
      roles.admin = true
    }
    if (role == 'Surveyor') {
      roles.surveyor = true
    }
    if (role == 'Surcon') {
      roles.surcon = true
    }
    if (role == 'Manager') {
      roles.manager = true
    }
    if (role == 'System') {
      roles.system = true
    }
    if (role == 'Appsn') {
      roles.appsn = true
    }

    state.roles = roles
  },
  setAuthUser: (state, credentials) => {
    state.userAccount = credentials
    state.loginStatus = true
    store.commit('setProgressStatus', false)
  },
  clearAuthUser: state => {
    state.loginStatus = false
    state.userAccount = state.noUser
    store.commit('setProgressStatus', false)
  },
  setAllRoles: roles => {
    state.roles = roles
  }
}

const actions = {
  getAllUsers(state) {
    Api()
      .get(Api_Endpoint + 'api/GetAllUsers')
      .then(response => {
        const users = response.data
        state.users = users
      })
      .catch(function (ex) {
        console.log(JSON.stringify(ex))
        store.commit('setProgressStatus', false)
      })
  },
  tryAutoLogin({ commit }) {
    try {
      //check if token exist
      const token = localStorage.getItem('token')
      if (!token) {
        commit('clearAuthCredentials')
        return router.replace('/login')
      }

      //check if date is valid
      const dateString = localStorage.getItem('expirationDate')
      const expirationDate = new Date(Date.parse(dateString))
      const now = new Date()
      if (
        now >= expirationDate ||
        expirationDate == undefined ||
        expirationDate == 'Invalid Date' ||
        expirationDate == null
      ) {
        commit('clearAuthCredentials')
        return router.replace('/login')
      }

      const userName = localStorage.getItem('userName')
      const role = localStorage.getItem('role')

      const access = {
        token: token,
        expirationDate: expirationDate,
        userName: userName,
        role: role
      }
      commit('setAuthUser', access)
      commit('setAuthUserRoles', access)
      return router.replace('/')
    } catch (e) {
      commit('clearAuthCredentials')
      return router.replace('/login')
    }
  },
  logout({ commit }) {
    commit('clearAuthCredentials')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    commit('clearAuthUser')
    router.replace('/login')
  },
  InitAllRoles(state) {
    Api()
      .get(Api_Endpoint + 'api/GetAllRoles')
      .then(response => {
        const roles = response.data
        state.roleList = roles
        store.commit('setProgressStatus', false)
      })
      .catch(function (ex) {
        console.log(JSON.stringify(ex))
        store.commit('setProgressStatus', false)
      })
  },
  VerifyOTP({ commit, state }, payload) {
    Api()
      .get(
        Api_Endpoint +
        'VerifyCode/' +
        state.userAccount.userName +
        '/' +
        payload.otp
      )
      .then(response => {
        const access = configResponse(
          response.data,
          state.userAccount.rememberMe
        )
        commit('setAuthCredentialsToLStorage', access)
        commit('setAuthUser', access)
        commit('setAuthUserRoles', access)
        router.replace('/')
      })
      .catch(function (ex) {
        try {
          store.commit('setProgressStatus', false)
          alert(JSON.stringify(ex.response))
        } catch (error) {
          store.commit('setProgressStatus', false)
          console.log(JSON.stringify(error))
        }
      })
  },
  SendOTP({ state }, payload) {
    Api()
      .get(Api_Endpoint + 'api/SendSecurityToken/' + payload.userName)
      .then(response => {
        store.commit('setProgressStatus', false)
        console.log(response)
        state.otpSent = true
      })
      .catch(function (ex) {
        try {
          store.commit('setProgressStatus', false)
          alert(JSON.stringify(ex.response))
        } catch (error) {
          store.commit('setProgressStatus', false)
          console.log(JSON.stringify(error))
          console.log(ex)
        }
      })
  },
  login({ commit, dispatch, state }, credentials) {
    //alert(JSON.stringify(credentials))
    Api()
      .post(Api_Endpoint + 'login', credentials)
      .then(response => {
        //alert(credentials.rememberMe)
        //alert(JSON.stringify(response.data))

        if (response.data.mfa == true) {
          state.mfa = true
          state.userAccount.rememberMe = credentials.rememberMe
          state.userAccount.userName = credentials.email
          dispatch('SendOTP', {
            userName: response.data.userName
          })
          router.replace('/verifyotp')
          const alert = {
            alertDialog: true,
            bgcolor: 'green',
            title: 'OTP SENT',
            snackbar: false,
            text: 'One time password sent, please enter it here',
            timeout: 4000
          }
          store.commit('setAlert', alert)
        } else {
          const access = configResponse(response.data, credentials.rememberMe)
          //alert(JSON.stringify(access))
          commit('setAuthCredentialsToLStorage', access)
          commit('setAuthUserRoles', access)
        }
      })
      .catch(function (ex) {
        store.commit('setProgressStatus', false)
        if (ex.response == undefined) {
          const alert = {
            alertDialog: true,
            bgcolor: 'red',
            title: 'Authentication Failed',
            snackbar: false,
            text: ex.message,
            timeout: 4000
          }
          store.commit('setAlert', alert)
          return
        }
        const alert = {
          alertDialog: true,
          bgcolor: 'red',
          title: 'Authentication Failed',
          snackbar: false,
          text: ex.response.data.message,
          timeout: 4000
        }
        store.commit('setAlert', alert)
      })
  },
  googleLogin() {
    const url =
      Api_Endpoint +
      '/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Floginsuccess.html&state=KvwAr14HtHvHbeVJc_FxBqSK_S0WmxqikQD_rqCQKRI1'
    window.location.href = url
  },
  createAccount({ state }, credentials) {
    Api()
      .post(Api_Endpoint + 'api/register', credentials)
      .then(response => {
        state.executionStatus = true
        // dispatch('getAllUsers')
        const alert = {
          alertDialog: true,
          bgcolor: 'green',
          title: 'Account Created!',
          snackbar: false,
          text: 'Congratulations, your account was created successfully.',
          timeout: 4000
        }
        //text: 'Your account was created successfully but we were unable to send email comfirmation link to your email. You can resend this confirmation link via your profile manager page',

        if (response.data.emailSent == false) {
          store.commit('setAlert', alert)
        } else {
          alert.bgcolor = 'green';
          alert.text = response.data.message;
          store.commit('setAlert', alert)
        }
        router.replace("/Login")
        store.commit('setProgressStatus', false)
      })
      .catch(function (ex) {
        //alert(JSON.stringify(ex.response.data.message))
        if (ex.response == undefined) {
          const valert = {
            alertDialog: true,
            bgcolor: 'red',
            title: 'Registration Failed',
            snackbar: false,
            text: ex.message,
            timeout: 4000
          }
          store.commit('setAlert', valert)
          store.commit('setProgressStatus', false)
        } else {
          const valert = {
            alertDialog: true,
            bgcolor: 'red',
            title: 'Registration Failed',
            snackbar: false,
            text: ex.response.data.message,
            timeout: 4000
          }
          store.commit('setAlert', valert)
          store.commit('setProgressStatus', false)
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
