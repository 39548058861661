import router from '../../router'
import Api from './Network'
import store from '../../store'
import AppSettings from '../AppSettings'
import { encriptPass, decriptPass, timeSince } from "../data";
const apiUrl = AppSettings.API_ENDPOINT

const state = {
  userJobs: [],
  students: [],
  tables: [],
  table: { tableName: '', settings: {}, primaryKey: '', rows: [], columns: [] },
  connection: {},
  selectedQuery:{},
  sqlQueries:[]
}
const getters = {
  getSqlQueries (state) {
    let req = state.sqlQueries
    try {
      req.forEach(function(item, index) {
        //alert(item.date)
        let timeSinceSaved =  timeSince(item.date);
       
         req[index].timeSince = timeSinceSaved;
      });
      //alert(JSON.stringify(req))
      return req;
    } catch (ex) {
      //alert("error " + JSON.stringify(ex))
      return req;
    }
  },
  getSelectedQuery (state) {
    return state.selectedQuery
  },
  getConnection (state) {
    return state.connection
  },
  getTable (state) {
    return state.table
  },
  getTables (state) {
    const req = state.tables
    return req
  },
  getYear: () => {
    const d = new Date()
    const y = d.getUTCFullYear()
    return y
  },
  getMonth () {
    const d = new Date()
    const m = d.getUTCMonth() // 0 - 11
    return m
  }

}
const mutations = {
  setSqlQueries (state, sqlQuery) { 
    let sqlQueries = state.sqlQueries
      if (sqlQueries == []) {
         sqlQueries[0] = sqlQuery;
      } else {
        sqlQueries.push(sqlQuery);
      }
      //alert(JSON.stringify(sqlQueries))
      state.sqlQueries = sqlQueries
      localStorage.setItem(
        "sqlQueries",
        encriptPass(JSON.stringify(sqlQueries))
      );
  },
  loadSqlQueries (state) { 
    const sqlQueries = JSON.parse(
      decriptPass(localStorage.getItem("sqlQueries"))
    );    
    state.sqlQueries = sqlQueries
  },
  setSelectedQuery (state, payload) {
    state.selectedQuery = payload
  },
  setConnection: (state, payload) => {
    state.connection = payload
  },
  setTable: (state, payload) => {
    state.table = payload
  },
  setTables: (state, payload) => {
    state.tables = payload
  },
  setStudents: (state, students) => {
    alert(JSON.stringify(students))
    state.students = students
  },
  setRequestList (state, payload) {
    state.requestList = payload
  }
}
const actions = {
  getUserProfile: commit => {
    const userName = localStorage.getItem('userName')
    // alert(userName)
    try {
      Api()
        .get(apiUrl + 'api/getSurveyor/' + userName)
        .then(response => {
          // alert (JSON.stringify (response.data));
          commit('setUserProfile', response.data)
          router.replace('/')
          store.commit('setProgressStatus', false)
          // store.commit('setAlert', 'user records loaded successfully')
        })
        .catch(function (ex) {
          router.replace('/login')
          store.commit('setProgressStatus', false)
          if (ex.response === undefined) {
            alert(
              'The server is temporarily unavailble, please try again later'
            )
          } else {
            alert(JSON.stringify(ex.response))
          }
        })
    } catch (error) {
      alert(JSON.stringify(error))
    }
  },
  update: (state, payload) => {
    try {
      Api()
        .put(apiUrl + 'api/Table/Update/' + payload.path, payload)
        .then(response => {
          store.commit('setProgressStatus', false)
          alert(
            'Record with ' +
              payload.options.primaryKey +
              ' = ' +
              response.data.updated +
              ' was updated successfully'
          )
        })
        .catch(function (ex) {
          alert(JSON.stringify(ex.response.data))
          state.progressStatus = false
        })
    } catch (error) {
      alert(JSON.stringify(error))
    }
  },
  
}
export default {
  state,
  getters,
  mutations,
  actions
}
