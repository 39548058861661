<template>
  <div>
   <div
        class="text-center"
        style="
          padding: 0px;
          margin:auto;
          text-align: center;
        ">
        <div class="mt-3">
          <img aspect-ratio="1" width="150" src="../assets/logo.png" />
          <p>Digitally Signed Verified Instruments</p>
        </div>
      </div>
    <v-row>
      <v-col class="col-lg-4 col-md-5 col-sm-7" style="margin: auto;">
        <!-- <h1 class="text-center mb-1 #bcd6e9--text">Ready to explore?</h1> -->
        <v-card-text style="padding-top: 10px;">
          <v-form v-model="valid">
            <v-text-field
              v-model="credential.email"
              label="Email"
              placeholder="Enter your email"
              prepend-inner-icon="mdi-email"
              hint="This is the email you used during sign up"
              :rules="[rules.required, rules.emailMatch]"
              dense
              outlined
              required
            ></v-text-field>

            <v-text-field
              v-model="credential.password"
              placeholder="Enter your password"
              prepend-inner-icon="mdi-key"
              :type="show1 ? 'text' : 'password'"
              dense
              outlined
              label="Password"
              required
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[
                rules.required,
                rules.min,
                rules.passUpperCase,
                rules.passLowerCase,
                rules.passNumber
              ]"
              name="input-10-1"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field
              v-model="credential.retypePassword"
              placeholder="Enter your password"
              prepend-inner-icon="mdi-key"
              :type="show1 ? 'text' : 'password'"
              dense
              outlined
              label="Password"
              required
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.passwordMatch]"
              name="input-10-1"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-btn
            tile
            block
            @click="SignUp()"
            :loading="loading"
            :disabled="!valid"
            class="primary"
            >Sign Up</v-btn
          >
        </v-card-actions>
        <v-divider class="ma-2"></v-divider>
        <div class="text-center ma-2">
          <br />
          <span class="link">Already a user?</span>
          <!-- <a tile text href="/Login" class="purple--text ma-2">Sign In</a> -->
          <v-btn tile text outlined to="/Login" class="primary--text ma-2"
            >Sign In</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="progress.visible" hide-overlay persistent width="300">
      <v-card color="progress" dark>
        <v-card-text>
          {{ progress.message }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
    >
      {{ alert.text }}
      <!-- <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>-->
    </v-snackbar>
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">card_membership</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text class="subTitle">
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true;
              alert.alertDialog = false;
            "
            >Retry</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Api2 from "../store/modules/Network2";
export default {
  data: function () {
    return {
      valid: false,
      loading: false,
      disableSignIn: false,
      isLoaded: false,
      country:'',
      city:'',
      credential: {
        country: this.country, 
        city: this.city,
        email: "",
        password: "",
        retypePassword: "",
        role: "Preview",
      },
      show1: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: (v) => /.+@.+/.test(v) || "The email is not valid",
        passUpperCase: (v) =>
          /[A-Z]/.test(v) || "password must have at least one UPPER CASE",
        passLowerCase: (v) =>
          /[a-z]/.test(v) || "password must have at least one LOWER CASE",
        passNumber: (v) =>
          /\d/.test(v) || "password must have at least one NUMBER",
        passSpecialChar: (v) =>
          /\W/.test(v) || "password must have at least one SPECIAL CHARACTER",
        passwordMatch: (v) =>
          v == this.credential.password ||
          "The password and re-type password you entered do not match",
      },
    };
  },
  computed: {
    loginId() {
      return this.$store.getters.getLoginId;
    },
    progress() {
      return this.$store.getters.getProgressStatus;
    },
    alert_message1() {
      return this.$store.getters.getAlertMessage;
    },
    alert() {
      return this.$store.getters.getAlert;
    },
  },
  watch: {
    alert: function () {
      this.disableSignIn = false;
      this.loading = false;
    },
  },
  methods: {
    googleLogin() {
      this.$store.dispatch("googleLogin");
      // this.$gAuth
      //   .signIn()
      //   .then(GoogleUser => {
      //     //on success do something
      //     console.log("GoogleUser", GoogleUser);
      //     alert(GoogleUser)
      //   })
      //   .catch(ex => {
      //     alert('error : ' + ex)
      //   });
    },
    SignUp: function () {
      if (!this.valid) {
        return;
      }
      this.loading = true;
      // const payload = {
      //   visible: true,
      //   message: "creating your account, please wait...",
      // };      
      this.disableSignIn = true;
      //this.$store.commit("setProgressStatus", payload);
      const credential =  this.credential
      credential.country = this.country
      credential.city = this.city
      //alert(JSON.stringify(credential))
      this.$store.dispatch("createAccount", credential);
    },
  },
   mounted() {
    const vm = this
    Api2()
      .get("https://extreme-ip-lookup.com/json")
      .then(response => {
       // alert(JSON.stringify(response.data))
         return vm.country = response.data.country,
          vm.city = response.data.city        
      })
  }
};
</script>

<style lang="scss">
.outer-wrapper {
  display: table;
  width: 100%;
  height: 95%;
  overflow: auto;
}

.inner-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
  overflow: auto;
}
</style>
