import Vue from 'vue'
export const data = {}

export const timeSince = date =>{
  let ndate
  try{
    ndate = new Date(date)
  } catch(ex){
    ndate = date
  }
  var seconds = Math.floor((new Date() - ndate) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export const encriptPass = plainPassword => {
  const a = Vue.CryptoJS.AES.encrypt(
    plainPassword,
    localStorage.getItem("userName")
  ).toString();
  //alert(a);
  return a;
}
export const decriptPass = encriptedPassword => {
  const a = Vue.CryptoJS.AES.decrypt(
    encriptedPassword,
    localStorage.getItem("userName")
  ).toString(Vue.CryptoJS.enc.Utf8);
  //alert(a);
  return a;
}

export const deleteUneditableColumns = (data, columns) => {
  try {
    columns.forEach(function(col) {
      //alert(JSON.stringify(col))
      const dtype = col.dataType.toLowerCase()
      const generated = col.generated
      const isIdentity = col.isIdentity
      if(dtype == 'image' ||
        dtype == 'byte' || dtype == 'bytes' || dtype == 'bytea' || dtype == 'binary' ||
        dtype == 'varbinary' || dtype == 'blob' || dtype == 'tinyblob' ||
        dtype == 'longblob' || dtype == 'geography' || dtype == 'geometry' ||
        dtype == 'point' || dtype == 'linestring' || dtype == 'polygon' ||
        dtype == 'multipoint' || dtype == 'pultilinestring' ||
        dtype == 'multipolygon' || dtype == 'geometrycollection' || dtype == 'json' || 
        dtype == 'line' || dtype == 'lseg' || dtype == 'box' || 
        dtype == 'path' || dtype == 'circle' ||
        generated == 'auto_increment' || generated== 'on update CURRENT_TIMESTAMP' || 
        generated == 'STORED GENERATED' || generated == 'VIRTUAL GENERATED'  || 
        generated ==  'DEFAULT_GENERATED' || isIdentity == 'YES'
      ){
          delete data[col.columnName]
      }
    });
    return data;
  } catch (ex) {
    alert(ex.message)
    return data;
  }
}

export const getFields = table => {
  if (table == null) {
    return
  }
  const newColumnList = []
  const checkcolumn = {
    type: 'checkbox',
      allowFiltering: false,
      allowSorting: false,
      allowEditing: false,
    width: '60'
  }
  const commands = [
    {
      type: 'Edit',
      buttonOption: {
        cssClass: 'e-flat',
        iconCss: 'e-edit e-icons'
      }
    },
    {
      type: 'Delete',
      buttonOption: {
        cssClass: 'e-flat',
        iconCss: 'e-delete e-icons'
      }
    },
    {
      type: 'Save',
      buttonOption: {
        cssClass: 'e-flat',
        iconCss: 'e-update e-icons'
      }
    },
    {
      type: 'Cancel',
      buttonOption: {
        cssClass: 'e-flat',
        iconCss: 'e-cancel-icon e-icons'
      }
    }
  ]
  const commandcolumn = {
    headerText: 'Actions',
    textAlign: 'Center',
    width: '100',
    commands: commands
  }
  newColumnList[0] = checkcolumn
  newColumnList[1] = commandcolumn

  const primaryKey = table.primaryKey
  const req = table.columns
  if (req != undefined) {
    req.forEach(function (item, i) {
      let dtype = item.dataType.toLowerCase()
      let dataType
      let editType
      let allowEditing = true
      if (
        dtype == 'character varying' ||
        dtype == 'character' ||
        dtype == 'varchar' ||
        dtype == 'char' ||
        dtype == 'nvarchar' ||
        dtype == 'nchar' ||
        dtype == 'text' ||
        dtype == 'string' ||
        dtype == 'oid'
      ) {
        dataType = 'string'
        editType = 'textedit'
        allowEditing = true
      } else if (
        dtype == 'integer' ||
        dtype == 'bigint' ||
        dtype == 'int' ||
        dtype == 'serial' ||
        dtype == 'smallint'
      ) {
        dataType = 'integer'
        editType = 'numericedit'
        allowEditing = true
      } else if (
        dtype == 'date' ||
        dtype == 'datetime' ||
        dtype == 'timestamp' ||
        dtype == 'datetime2'
      ) {
        dataType = 'date'
        editType = 'datepickeredit'
        allowEditing = true
      } else if (
        dtype == 'float' ||
        dtype == 'double' ||
        dtype == 'number' ||
        dtype == 'double precision' ||
        dtype == 'numeric' ||
        dtype == 'real' ||
        dtype == 'single'
      ) {
        dataType = 'number'
        editType = 'numericedit'
        allowEditing = true
      } else if (dtype == 'bit' || dtype == 'bool' || dtype == 'bit varying') {
        dataType = 'boolean'
        editType = 'dropdownedit'
        allowEditing = true
      } else if (
        dtype == 'image' ||
        dtype == 'byte' ||
        dtype == 'bytes' ||
        dtype == 'bytea' ||
        dtype == 'binary' ||
        dtype == 'varbinary' ||
        dtype == 'blob' ||
        dtype == 'tinyblob' ||
        dtype == 'longblob' ||
        dtype == 'geography' ||
        dtype == 'geometry' ||
        dtype == 'point' ||
        dtype == 'linestring' ||
        dtype == 'polygon' ||
        dtype == 'multipoint' ||
        dtype == 'pultilinestring' ||
        dtype == 'multipolygon' ||
        dtype == 'geometrycollection' ||
        dtype == 'json' ||
        dtype == 'line' ||
        dtype == 'lseg' ||
        dtype == 'box' ||
        dtype == 'path' ||
        dtype == 'circle' 
      ) {
        dataType = 'string'
        editType = 'textedit'
        allowEditing = false
      } else {
        dataType = 'string'
        editType = 'textedit'
      }

      if (item.columnName === primaryKey) {
        const newCol = {
          field: item.columnName,
          headerText: item.columnName,
          width: 150,
          isPrimaryKey: true,
          textAlign: 'Left',
          type: dataType,
          allowEditing: false
        }
        newColumnList[i + 2] = newCol
      } else {
        const newCol = {
          field: item.columnName,
          headerText: item.columnName,
          width: 150,
          isPrimaryKey: false,
          textAlign: 'Left',
          type: dataType,
          editType: editType,
          allowEditing: allowEditing
        }
        newColumnList[i + 2] = newCol
      }
    })
    return newColumnList
  } else {
    const firstRow = table.rows[0]
    var i = 0;
    for (const key in firstRow) {
      if (key === primaryKey) {
        const newCol = {
          field: key,
          headerText: key,
          width: 150,
          isPrimaryKey: true,
          textAlign: 'Left',
          type: 'string',
          allowEditing: false
        }
        newColumnList[i + 2] = newCol
      } else {
        const newCol = {
          field: key,
          headerText: key,
          width: 150,
          isPrimaryKey: false,
          textAlign: 'Left',
          type: 'string',
          allowEditing: true
        }
        newColumnList[i + 2] = newCol
      }
      i++
    }
    return newColumnList
  }
}
