<template>
  <div>
    <v-btn absolute fab bottom right  class="accent black--text"  to="Design"
      style="position:fixed;bottom:25px;right:25px;">
    <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-toolbar dense flat elevation="5">
      <v-btn icon to="/Settings">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <!--<v-icon x-small>mdi-database</v-icon>
      {{connection.database}}
       /-->
      <v-icon x-small>mdi-table</v-icon>
      <v-badge :content="tables.length" :value="tables.length" color="red">Tables</v-badge>
      <v-spacer></v-spacer>   
      
        <v-col class="mb-n6 col-lg-2 col-xs-6" v-show="show_search">
            <v-text-field 
                v-model="search"
                placeholder="search"
                dense
                required
              ></v-text-field>  
        </v-col>                            
      <v-btn icon @click="show_search = !show_search">
        <v-icon>search</v-icon>
      </v-btn>
    </v-toolbar>
  
     <table-list :search = search class="ma-1"></table-list>

      <v-dialog v-model="showProgress" class="pa-3" hide-overlay persistent width="400">
      <v-card>
        <v-card-text class="white">
          Opening table records, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.color"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
    >{{ alert.text }}</v-snackbar>
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor=='red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{alert.title}}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{alert.text}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor=='red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
          >Cancel</v-btn>
          <v-btn
            v-if="alert.bgcolor=='red'"
            text
            @click="showDialog = true; alert.alertDialog=false"
          >Retry</v-btn>
          <v-btn v-else color="green" text @click="showDialog = false; alert.alertDialog=false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-btn dark absolute fab right class="accent" @click="showDialog=true">
      <v-icon>mdi-plus</v-icon>
    </v-btn> -->
  </div>
</template>
<script>
import Api from "../store/modules/Network";
import router from "../router";
import AppSettings from "../../src/store/AppSettings";
import TableList from "../components/Tables";
const apiUrl = AppSettings.API_ENDPOINT;

export default {
  components: {
    TableList
  },

  data: function() {
    return {
      search:'',
      show_search:false,
      filteredTables:[],
      selectedNodeIds: [],
      graphWithNodes: {
        id: 1,
        name: "The graph",
        nodes: [
          {
            id: 1,
            graphId: 1
          },
          {
            id: 2,
            graphId: 1
          }
        ]
      },
      showColumnChoser: false,
      selectedTable: { columns: [], selectedColumns: [], tableName: "" },
      selectedColumns: [],
      selected: "",
      dbType: ["mssql", "mysql", "pgsql", "sqllite"],
      tableSettingsDialog: false,
      showProgress: false,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Database Conection",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top"
      },
      activeTable: {}
    };
  },
  filters: {
    getIcon: function(value) {
      return "http://localhost:8080/public/img/icons/" + value + ".png";
    },
    getIconColor: function(value) {
      if (value == "done") {
        return "success";
      } else {
        return "error";
      }
    },
    getColumns: function(value) {
      try {
        var col = [];
        col = "[" + JSON.parse(value) + "]";
        alert(col);
        return col;
      } catch (ex) {
        alert(ex.message);
      }
    }
  },
  computed: {
    progress_status() {
      return this.$store.getters.getProgressStatus;
    },
    tables: {
      get: function() {
        let req = this.$store.getters.getTables;
        try {
          req.forEach(function(item, index) {
            let selCol = req[index].columns;
            var col = '["' + selCol.split(",").join('","') + '"]';
            req[index].columns = JSON.parse(col);
          });
          return req;
        } catch (ex) {
          return req;
        }
      },
      set: function(value) {
        alert(JSON.stringify(value));
        //return (this.tables = value);
      }
    },
    connection: function() {
      return this.$store.getters.getConnection;
    },
    noColumnsSelected() {
      return this.selectedColumns.length === 0;
    },
    someColumnsSelected() {
      return this.selectedColumns.length > 0 && !this.allColumnsSelected;
    },
    allColumnsSelected() {
      return this.selectedColumns.length === this.selectedTable.columns.length;
    }
  },
  methods: {
    toggleCompleteSelection() {
      if (this.noColumnsSelected || this.someColumnsSelected) {
        this.selectedColumns = this.selectedTable.columns.map(node => node);
      } else {
        this.selectedColumns = [];
      }
    },
    saveColumns() {
      let columns = this.selectedColumns;
      let tableName = this.selectedTable.tableName;
      this.activeTable = { tableName: tableName, columns: columns };
      this.getTableRecords(this.activeTable);
      this.tableSettingsDialog = false;
    },
    showTableSettings(item) {
      this.tableSettingsDialog = true;
      this.selectedTable = item;
      // alert("active table :" + JSON.stringify( this.activeTable));
    },
    getTableRecords(item) {
      this.showProgress = true;
      const vm = this;
      this.connection.columns = this.selectedColumns;
      this.connection.tableName = item.tableName;
      try {
        Api()
          .put(apiUrl + "api/table/" + item.tableName, this.connection)
          .then(response => {
            this.$store.commit("setTable", response.data);
            router.push("SelTable");
            return (this.showProgress = false), (this.showDialog = false);
          })
          .catch(function(ex) {
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        //alert(JSON.stringify(e))
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"),
          (this.alert.text = e.message),
          (this.alert.title = "Table Records")
        );
      }
    },
    GetTables() {
      this.showProgress = true;
      const vm = this;
      try {
        Api()
          .post(apiUrl + "api/database/tables", this.connection)
          .then(response => {
            return (
              (this.showProgress = false),
              (this.tables = response.data),
              (this.showDialog = false),
              (this.alert.snackbar = true),
              (this.alert.text = response.data.message)
            );
          })
          .catch(function(ex) {
            if (ex.response != undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
    changeData() {
      this.message = "The Spirit of God is Exellent";
    }
  },
  watch: {
    // search:{function(search) {
    //   const filteredTables = this.tables.filter(item => item.tableName.toLowerCase().includes(search));
    //   return this.filteredTables = filteredTables;
    // }},
    tables2: {
      handler: function(req) {
        alert(req);
        req.forEach(function(item, index) {
          let selCol = req[index].columns;
          var col = '["' + selCol.split(",").join('","') + '"]';
          req[index].columns = JSON.parse(col);
        });
        return (this.tables2 = req);
      },
      deep: true
    }
  },
  //watch:{ myComputedProperty : { handler(newVal){ do something when change happens}, deep: true}}

  created() {}
};
</script>

<style lang="scss" scoped>
.list-db-featureclass {
  margin: 10px;
  max-height: 200px;
  overflow: auto;
}
</style>