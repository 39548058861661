<template>
  <div>
    <v-btn
      absolute
      fab
      class="accent black--text mb-1"
      @click="addTable()"
      style="position:fixed;bottom:25px;right:25px;"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-toolbar dense elevation="5">
      <v-btn icon>
        <v-icon
          small
          @click.stop="tablePanel = !tablePanel; if(tablePanel == true){panel2 = 'col-md-9'} else{panel2 = 'col-md-12'}"
        >fa-list</v-icon>
      </v-btn>
      <!-- <v-toolbar-title>Table Schema Design</v-toolbar-title> -->
      <v-btn text @click="addTable()" class="ma-1">
        <v-icon>mdi-plus</v-icon>New Table
      </v-btn>
      <v-btn text @click="CreateTable()" class="ma-1">
        <v-icon>done</v-icon>Submit
      </v-btn>
      <!-- <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
      </v-btn>-->
    </v-toolbar>
    <v-divider></v-divider>

    <v-row no-gutters class="ma-1">
      <v-col class="col-md-3 white--text" v-show="tablePanel">
        <table-list style="height:68vh; overflow:auto"></table-list>
      </v-col>
      <v-col :class="panel2">
        <v-row no-gutters>
          <v-col v-for="(table, i) in design" :key="i">
            <v-card
              class="ma-1 mx-2 left-align"
              max-width="320"
              min-width="250"
              :style="table.style"
            >
              <v-toolbar flat dense style="background-color:#F8F9F9">
                <!-- <v-icon>mdi-table</v-icon> -->
                <div text @click="showData(table)" v-if="table.edit==false">{{table.tableName}}</div>
                <!-- <v-card-subtitle v-if="table.edit==false">{{table.tableName}}</v-card-subtitle> -->
                <v-text-field v-else v-model="table.tableName" hide-details="auto"></v-text-field>
                <v-btn icon v-if="table.edit==false" @click="table.edit = 'true'">
                  <v-icon small>fa-edit</v-icon>
                </v-btn>
                <v-btn icon v-else @click="table.edit = false">
                  <v-icon>done</v-icon>
                </v-btn>
                <v-btn text icon @click="showColumnDialog(table)">
                  <v-icon small>fa-plus</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon @click="removeDesignTable(i)">
                  <v-icon small>fa-times</v-icon>
                </v-btn>
              </v-toolbar>
              <!-- <v-divider></v-divider> -->
              <v-list elevation="1" dense>
                <v-list-item v-for="(item, j) in table.columns" :key="j" link dense>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters>
                        <v-col class="col-md-5 pb-n2">
                          <v-list-item-title class="font-weight-light text-truncate">
                            <v-icon color="green" small v-show="item.primary">mdi-key</v-icon>
                            {{item.field}}
                          </v-list-item-title>
                        </v-col>
                        <v-col class="col-md-5 pb-n2">
                          <v-list-item-title
                            class="font-weight-light text-truncate"
                          >{{item.type}}({{item.size}})</v-list-item-title>
                        </v-col>
                        <v-col class="col-md-2 pb-n2">
                          <v-list-item-title class="font-weight-light text-truncate">
                            <v-icon color="green" small v-show="item.nullable">mdi-null</v-icon>
                          </v-list-item-title>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                    <!-- <v-list-item-title v-text="item.type"></v-list-item-title> -->
                  </v-list-item-content>
                  <!-- <v-list-item-action>
                <v-btn icon>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                  </v-list-item-action>-->
                  <!-- @mouseover="" -->
                  <v-list-item-action dense>
                    <v-menu
                      bottom
                      :offset-x="true"
                      open-on-hover
                      :close-on-click="false"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="grey" icon v-bind="attrs" v-on="on" small class="my-n1">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item link class="my-n2">
                          <v-list-item-title>
                            <v-switch v-model="item.nullable" label="Allow Nulls" color="blue"></v-switch>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link class="my-n2">
                          <v-list-item-title>
                            <v-switch v-model="item.primary" label="Primary Key" color="blue"></v-switch>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link class="my-n2">
                          <v-list-item-title>
                            <v-switch v-model="item.unique" label="Unique Key" color="blue"></v-switch>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link class="my-n2">
                          <v-list-item-title>
                            <v-switch v-model="item.index" label="Index" color="blue"></v-switch>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="400px">
      <v-card>
        <div class="text-center" style="padding:10px;magin-bottom:10px">
          <h3 class="text-center">
            <v-icon>mdi-draw</v-icon>Column definition
          </h3>
        </div>
        <v-card-text>
          <v-row no-gutters>
            <v-col col-md-6 col-xs-12>
              <v-text-field v-model="newCol.field" label="Field Name" required hide-details="auto"></v-text-field>
            </v-col>
            <v-col col-md-6 col-xs-12>
              <v-select
                v-model="newCol.type"
                :items="dataType.mssql"
                label="Data Type"
                hide-details="auto"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-6 col-xs-12>
              <v-text-field
                type="number"
                v-model="newCol.size"
                label="Data Size"
                hide-details="auto"
                required
              ></v-text-field>
            </v-col>
            <v-col col-md-6 col-xs-12>
              <v-checkbox v-model="newCol.primary" label="Primary Key" required hide-details="auto"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col col-md-6 col-xs-12>
              <v-switch v-model="newCol.nullable" label="Nullable" hide-details="auto"></v-switch>
            </v-col>
            <v-col col-md-4 col-xs-12>
              <v-switch v-model="newCol.unique" label="Unique" hide-details="auto"></v-switch>
            </v-col>
            <v-col col-md-4 col-xs-12>
              <v-switch v-model="newCol.identity" label="Identity" hide-details="auto"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-4" text @click="showDialog = false">Close</v-btn>
          <v-btn
            color="blue darken-4"
            v-if="newCol.field == 'null'"
            disabled
            text
            @click="AddColumn()"
          >Add Column</v-btn>
          <v-btn color="blue darken-4" v-else text @click="AddColumn()">Add Column</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showProgress" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Connecting to your database, please wait...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.color"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
    >
      <v-icon v-if="alert.color=='red'">mdi-cancel</v-icon>
      <v-icon v-else>done</v-icon>
      {{ alert.text }}
    </v-snackbar>
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
          >Cancel</v-btn>
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="
              showDialog = true
              alert.alertDialog = false
            "
          >Retry</v-btn>
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false
              alert.alertDialog = false
            "
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "../store/modules/Network";
import router from "../router";
import AppSettings from "../store/AppSettings";
import TableList from "../components/Tables";
import Vue from "vue";
import {
  GridPlugin,
  RowDD,
  Filter,
  Page,
  Selection,
  Sort,
  Resize,
  ColumnMenu,
  PdfExport,
  ExcelExport,
  Toolbar,
  Reorder,
  Group,
  ColumnChooser,
  ContextMenu,
  Edit,
  CommandColumn,
  ForeignKey,
  Freeze
} from "@syncfusion/ej2-vue-grids";
// import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
Vue.use(GridPlugin);
const apiUrl = AppSettings.API_ENDPOINT;
export default {
  components: {
    TableList
  },
  data: function() {
    return {
      tablePanel: true,
      panel2: "col-md-9",
      activeTable: {},
      edit: false,
      newTableName: "New Table",
      cardStyle: "border-top: 5px solid green;",
      connections: [],
      newCol: {
        field: null,
        type: null,
        size: null,
        primary: false,
        nullable: true,
        unique: false,
        identity: false,
        index: false
      },
      newColEmpty: {
        field: null,
        type: null,
        size: null,
        primary: false,
        nullable: true,
        unique: false,
        identity: false,
        index: false
      },
      design: [
        // {
        //   tableName: "table1",
        //   edit: false, style:'border-top: 5px solid green;',
        //   columns: [
        //     {
        //       field: "Id",
        //       type: "int",
        //       size: 4,
        //       primary: true,
        //       nullable: false,
        //       unique: true,
        //       identity: true
        //     },
        //     {
        //       field: "Name",
        //       type: "varchar",
        //       size: 20,
        //       primary: false,
        //       nullable: false,
        //       unique: false,
        //       identity: false
        //     },
        //      {
        //       field: "PhoneNo",
        //       type: "int",
        //       size: 11,
        //       primary: false,
        //       nullable: false,
        //       unique: false,
        //       identity: false
        //     }
        //   ]
        // },
        // {
        //   tableName: "table2",
        //   edit: false, style:'border-top: 5px solid blue;',
        //   columns: [
        //     {
        //       field: "Id",
        //       type: "int",
        //       size: 4,
        //       primary: true,
        //       nullable: false,
        //       unique: true,
        //       identity: true
        //     },
        //     {
        //       field: "Name",
        //       type: "varchar",
        //       size: 20,
        //       primary: false,
        //       nullable: false,
        //       unique: false,
        //       identity: false
        //     }
        //   ]
        // }
      ],
      dataType: {
        mssql: [
          "int",
          "numeric",
          "varchar",
          "nvarchar",
          "bit",
          "date",
          "datetime",
          "varbinary",
          "geography"
        ],
        mysql: [
          "int",
          "numeric",
          "bit",
          "date",
          "datetime",
          "varchar",
          "nvarchar",
          "varbinary",
          "geography"
        ],
        pgsql: [
          "int",
          "numeric",
          "bit",
          "date",
          "datetime",
          "varchar",
          "nvarchar",
          "varbinary",
          "geography"
        ]
      },
      selectionSettings: {
        persistSelection: true,
        type: "Multiple",
        checkboxOnly: true
      },
      toolbar: ["Add", "Delete", "Update", "Cancel", "Search"],
      pageSettings: { pageSizes: [12, 50, 100, 200, 500], pageCount: 5 },
      serverPageSettings: {
        size: 500,
        skip: 0,
        pageno: 1
      },
      filterOptions: {
        type: "Menu"
      },
      filter: {
        type: "CheckBox"
      },
      contextMenuItems: [
        "AutoFit",
        "AutoFitAll",
        "SortAscending",
        "SortDescending",
        "Copy",
        "Edit",
        "Delete",
        "Save",
        "Cancel",
        "PdfExport",
        "ExcelExport",
        "CsvExport",
        "FirstPage",
        "PrevPage",
        "LastPage",
        "NextPage"
      ],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: "Normal" //"Normal" 'Batch'
      },
      commands: [
        {
          type: "Edit",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons"
          }
        },
        {
          type: "Delete",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-delete e-icons"
          }
        },
        {
          type: "Save",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-update e-icons"
          }
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons"
          }
        }
      ],
      dbType: ["mssql", "mysql", "pgsql", "sqllite"],
      showDialog: false,
      showProgress: false,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "Table Creation",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top"
      }
    };
  },
  filters: {
    getIcon: function(value) {
      return "http://localhost:8080/public/img/icons/" + value + ".png";
    },
    getIconColor(value) {
      if (value == "done") {
        return "success";
      } else {
        return "error";
      }
    }
  },
  computed: {
    editor() {
      // get current editor object
      return this.$refs.sqlText.editor;
    },
    connection: function() {
      return this.$store.getters.getConnection;
    },
    tables() {
      let req = this.$store.getters.getTables;
      return req;
    },
    progressStatus() {
      return this.$store.getters.getProgressStatus;
    }
    // table() {
    //   let req = this.$store.getters.getTable;
    //   const columns = getFields(req);
    //   req.columns = columns;
    //   return req;
    // }
  },
  methods: {
    removeDesignTable(i) {
      return this.design.splice(i, 1);
    },
    CreateTable() {
      this.showProgress = true;
      const vm = this;
      try {
        var connections = [];
        this.connection.tableSchema = this.design;
        Api()
          .post(apiUrl + "api/table/create", this.connection)
          .then(response => {
             this.$store.commit("setTables", response.data);
            return (
              (this.showProgress = false),
              (this.connections = connections),
              (this.showDialog = false),
              (this.alert.alertDialog = true),
              (this.alert.snackbar = true),
              (this.alert.bgcolor = "green"),
              (this.alert.text = "Table(s) creation was/were successful"),
              (this.design = [])
            );
          })
          .catch(function(ex) {
            return (
              (vm.showProgress = false),
              (vm.showDialog = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          });
      } catch (e) {
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
    addTable() {
      let color =
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
      let style = "border-top: 5px solid " + color;
      let table = {
        tableName: "table" + (this.design.length + 1),
        columns: [],
        edit: true,
        style: style
      };
      this.design.push(table);
    },
    showData(table) {
      alert(JSON.stringify(table));
    },
    showColumnDialog(table) {
      return (
        (this.showDialog = true), (this.activeTable = table), (this.newCol = {})
      );
    },
    AddColumn() {
      if (this.newCol.field == null || this.newCol.type == null) {
        this.alert.text = "enter a column name and data type first";
        return this.alert.color = "red", this.alert.snackbar = true;
      }
      if (this.newCol.primary == null){
        this.newCol.primary = false
      }
      if (this.newCol.identity == null){
        this.newCol.identity = false
      }
      if (this.newCol.index == null){
        this.newCol.index = false
      }
      if (this.newCol.nullable == null){
        this.newCol.nullable = false
      }
      if (this.newCol.unique == null){
        this.newCol.unique = false
      }
      //  this.activeTable.forEach(element => {
      //   if(element.primary == null){
      //     element.primary = false
      //   }
      //   if(element.identity== null){
      //     element.identity = false
      //   }
      //   if(element.nullable == null){
      //     element.nullable = false
      //   }
      //   if(element.index == null){
      //     element.index = false
      //   }
      //   if(element.unique== null){
      //     element.unique = false
      //   }
      // });
      let activeTable = this.activeTable.columns.push(this.newCol);
      const search = activeTable.tableName;
      for (var i = 0; i < this.design.length; i++) {
        if (this.design[i].tableName == search) {
          this.design[i] = activeTable;
        }
      }
     
      //let mytable = this.design.filter(item => item.tableName.includes(search))
      //let design = this.design.push(this.newCol);
      return (
        (this.alert.snackbar = true),
        (this.alert.text = "column added successfully"),
        (this.alert.color = "green"),
        (this.newCol = {}),
        this.design
      );
    },
    GetTables(connection) {
      this.showProgress = true;
      const vm = this;
      try {
        Api()
          .post("api/database/tables", connection)
          .then(response => {
            var today = new Date();
            var time =
              today.getHours() +
              ":" +
              today.getMinutes() +
              ":" +
              today.getSeconds();
            connection.time = time;
            this.$store.commit("setTables", response.data);
            this.$store.commit("setConnection", connection);
            router.push("Tables");
            return (
              (this.showProgress = false),
              (this.tables = response.data),
              (this.showDialog = false),
              (this.alert.snackbar = true),
              (this.alert.text = response.data.message)
            );
          })
          .catch(function(ex) {
            if (ex.response !== undefined) {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.response.data.message)
              );
            } else {
              return (
                (vm.showProgress = false),
                (vm.showDialog = false),
                (vm.alert.alertDialog = true),
                (vm.alert.bgcolor = "red"),
                (vm.alert.text = ex.message)
              );
            }
          });
      } catch (e) {
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
    SaveConnection() {
      this.showProgress = true;
      const vm = this;
      try {
        var connections = [];
        Api()
          .post(apiUrl + "api/database/testconnection", this.connection)
          .then(response => {
            let dbcon = this.connection;
            dbcon.db.noOfTables = response.data.noOfTables;

            if (this.connections == null) {
              connections[0] = dbcon;
            } else {
              connections = this.connections;
              connections.push(dbcon);
            }
            localStorage.setItem("connections", JSON.stringify(connections));

            return (
              (this.showProgress = false),
              (this.connections = connections),
              (this.showDialog = false),
              (this.alert.alertDialog = true),
              (this.alert.snackbar = true),
              (this.alert.bgcolor = "green"),
              (this.alert.text = response.data.message)
            );
          })
          .catch(function(ex) {
            return (
              (vm.showProgress = false),
              (vm.showDialog = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          });
      } catch (e) {
        return (
          (this.showDialog = false),
          (this.alert.alertDialog = true),
          (this.alert.bgcolor = "red"((this.alert.text = e.message)))
        );
      }
    },
    changeData() {
      this.message = "The Spirit of God is Exellent";
    },
    onRowSelected: function(args) {
      //alert(JSON.stringify(args.data));
      if (
        args.requestType !== "beginEdit" ||
        args.requestType !== "add" ||
        args.requestType !== "delete"
      ) {
        const record = args.data;
        //this.dialog = true;
        //let columns = this.table.columns;
        //alert(JSON.stringify(record));
        let fields = [];
        let i = 0;
        let f;
        // columns.forEach(function(item) {
        for (const key in record) {
          //     if (key == item.columnName) {
          //       let isNullable;
          //       if (item.isNullable == 'YES') {
          //         isNullable = true
          //       } else{
          //         isNullable = false
          //       }
          if (key == this.table.primaryKey) {
            //alert(key);
            f = {
              field: key,
              value: record[key],
              primaryKey: true,
              readOnly: true,
              disabled: true,
              type: "text",
              icon: "mdi-key"
            };
            fields[i] = f;
          } else {
            f = {
              field: key,
              value: record[key],
              primaryKey: false,
              readOnly: false,
              disabled: false,
              //required : isNullable,
              icon: "mdi-pencil",
              type: "text",
              editType: "datepickeredit or dropdownedit or numericedit"
            };
            fields[i] = f;
          }
          i++;
        }
        //   }
        // });
        this.selRecord = fields;
        this.selection = args.data;
      }
    },
    actionComplete(args) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        //nothing here
      }
    },
    actionBegin: function(args) {
      if (args.requestType == "save") {
        if (args.action == "edit") {
          //const selectedrecord = args.data;
          // const id = selectedrecord[this.table.primaryKey];
          // let connection = this.connection
          // connection.editData = args.data
          // connection.pKeyValue = args.data[this.table.primaryKey]
          // connection.primaryKey = this.table.primaryKey
          // connection.pKeyField = this.table.primaryKey
          // this.update(connection)
          //this.$store.dispatch("update", payload);
        }
        if (args.action == "add") {
          // const selectedrecord = args.data;
          // const id = selectedrecord[this.table.primaryKey];
          // const payload = {
          //   path: id,
          //   options: this.tableoptions,
          //   data: args.data
          // };
          // let connection = this.connection
          // connection.insertData = selectedrecord
          // this.insert(connection)
          //this.$store.dispatch("insert", payload);
          return;
        }
      }
      if (args.requestType == "delete") {
        // const selectedDelRecord = args.data[0]
        // const id = selectedDelRecord[this.table.primaryKey]
        // const payload = {
        //   path: id,
        //   options: this.tableoptions,
        //   data: this.tableoptions
        // }
        // this.$store.dispatch('delete', payload)
        // this.$store.commit('setProgressStatus', true)
      }
    }
  },
  provide: {
    grid: [
      Filter,
      RowDD,
      Page,
      Selection,
      Sort,
      Resize,
      ColumnMenu,
      Reorder,
      PdfExport,
      ExcelExport,
      Toolbar,
      Group,
      ContextMenu,
      Edit,
      CommandColumn,
      ColumnChooser,
      ForeignKey,
      Freeze
    ]
  },
  created() {
    const connections = JSON.parse(localStorage.getItem("connections"));
    return (this.connections = connections);
  }
};
</script>

<style lang="scss" scoped>
.list-db-featureclass {
  margin: 10px;
  max-height: 200px;
  overflow: auto;
}
.e-grid .e-gridheader {
  display: none;
}
.e-grid .e-icon-gdownarrow,
.e-grid .e-icon-grightarrow {
  text-indent: 1px;
}

.e-grid .e-gridheader {
  display: none;
}

.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse {
  width: 5px;
  padding-left: 10px;
}

.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse {
  padding-left: 20px;
}
</style>
